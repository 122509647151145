import money from './moneyshakthi.png'
import './App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';


const imageStyle = {
  border: '5px solid white',
    borderRadius: '10px',
    
    
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={money} className="App-logo" alt="logo" style={imageStyle} />
        
        <Container className="m-2" >
      <Row className="justify-content-md-center" >
        <Col md="auto" >
        <a className="App-link" href="/"
          target="_blank"
          rel="noopener noreferrer"
        >About Us</a></Col>
        <Col md="auto"><a
          className="App-link"
          href="/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a></Col>
      </Row>
      </Container>
      
      </header> 
     </div>
  );
}

export default App;
